import {ADM_KH, DAS_ALL, KH, VA, LAG_GS} from "AppConfig/Config";

export default class {

    constructor( result ) {
        this.result = result;
    }

    mapFieldToCsv = [
        { key: 'module', name: 'PSNVERFAHREN' },
        { key: 'evalYear', name: 'STNJAHR' },
        { key: 'subModule', name: 'AWMODUL' },
        { key: 'specYear', name: 'SPEZJAHR' },
        { key: 'reportPeriod', name: 'BERICHTSZEITRAUM' },
        { key: 'indicatorId',  name: 'QIID' },
        { key: 'indicatorType',  name: 'QITYP' },
        { key: 'indicatorDesc',  name: 'QIBESCHR1' },
        { key: 'pseudonym', name: 'UNIT', parse: this.getPseudonym.bind(this) },
        { key: 'pseudonym', name: 'PSNID', parse: this.getPseudonymId.bind(this) },
        { key: 'careProvider', name: 'IDENT_NUMMER', parse: this.getCareProviderIdent.bind(this) },
        { key: 'careProvider', name: 'STANDORT', parse: this.getCareProviderDestination.bind(this) },
        { key: 'numerator', name: 'ZAEHLER', type: 'FLOAT' },
        { key: 'numeratorE', name: 'E-ZAEHLER', type: 'FLOAT' },
        { key: 'denominator', name: 'NENNER', type: 'FLOAT' },
        { key: 'result', name: 'ERGEBNIS', type: 'FLOAT'  },
        { key: 'ciBottom', name: 'VERTRBER_UNTEN', type: 'FLOAT'  },
        { key: 'ciTop', name: 'VERTRBER_OBEN', type: 'FLOAT'  },
        { key: 'assessmentCalc', name: 'BEWERTUNG_RECH', type: 'NULLABLE_BOOLEAN' },
        { key: 'assessmentStat', name: 'BEWERTUNG_STAT' },
        { key: 'indicatorUnit', name: 'EINHEIT' },
        { key: 'significanceValue', name: 'SIGNIFIKANZWERT', type: 'FLOAT' },
        { key: 'refValue', name: 'REFWERT1', type: 'FLOAT' },
        { key: 'refOp', name: 'REFOPERATOR1' },
        { key: 'refCalc', name: 'REFBERECHNUNG' },
        { key: 'tendency', name: 'TENDENZ' },
        { key: 'comparability', name: 'VERGLEICHBARKEIT' },
        { key: 'abnormalOps', name: 'AUFFAELLIGE_VGNR' },
        { key: 'thresholdValue', name: 'SCHWELLENWERT' },
        { key: 'compYear', name: 'VERGLEICHS_ZEITRAUM', type: 'NULLABLE_BOOLEAN' },
        { key: 'evalDate', name: 'ERZEUGUNGSDATUM', parse: this.getDate.bind(this) },
        { key: 'listType', name: 'LISTENTYP' },
        { key: 'qikType', name: 'QIK_Art' },
        { key: 'ratingCode', name: 'BEWERTUNG_CODE', parse: this.getRatingCode.bind(this) },
        { key: 'commentQb', name: 'KOMMENTAR_QB', parse: this.getCommentQb.bind(this) },
        { key: 'commentInternal', name: 'KOMMENTAR_INTERN', type: 'STRING', parse: this.getCommentInternal.bind(this) },
        { key: 'resultComment', name: 'KOMMENTAR_LE', type: 'STRING', parse: this.getResultComment.bind(this) },
    ];

    checkUserRole = () => {};

    getRatingCode() {

        if(!this.checkUserRole([KH, VA, ADM_KH, LAG_GS])) {
            return '__NO_PRINT';
        }
        if(!this.result.lagRatingResponseDate && !this.checkUserRole([LAG_GS])) {
            return '__NO_PRINT';
        }

        return this.result.ratingCode;
    }

    getCommentQb() {

        if(!this.checkUserRole([KH, VA, ADM_KH, LAG_GS])) {
            return '__NO_PRINT';
        }
        if(!this.result.lagRatingResponseDate && !this.checkUserRole([LAG_GS])) {
            return '__NO_PRINT';
        }

        return this.result.commentQb?.replace(/\r\n?|\n/g,' ');
    }

    getCommentInternal() {

        if(!this.checkUserRole([KH, VA, ADM_KH, LAG_GS])) {
            return '__NO_PRINT';
        }
        if(!this.result.lagRatingResponseDate && !this.checkUserRole([LAG_GS])) {
            return '__NO_PRINT';
        }

        return this.result.commentInternal?.replace(/\r\n?|\n/g,' ');
    }

    getResultComment() {

        if(!this.checkUserRole([KH, VA, ADM_KH, LAG_GS])) {
            return '__NO_PRINT';
        }
        if(!this.result.lagRatingResponseDate && !this.checkUserRole([LAG_GS])) {
            return '__NO_PRINT';
        }

        return this.result.resultComment?.replace(/\r\n?|\n/g,' ');
    }

    getCareProviderIdent() {
        if(!this.checkUserRole([...DAS_ALL, KH, VA, ADM_KH])) {
            return '__NO_PRINT';
        }

        const { careProvider } = this.result;
        if(!careProvider) {
            return '';
        }
        const { identity } = careProvider;
        if(!identity) {
            return '';
        }
        return `${identity.identNumber}`;
    }

    getCareProviderDestination(key, type) {
        if(!this.checkUserRole([...DAS_ALL, KH, VA, ADM_KH])) {
            return '__NO_PRINT';
        }
        const { careProvider } = this.result;
        if(!careProvider) {
            return '';
        }
        const { identity } = careProvider;
        if(!identity) {
            return '';
        }
        return `${identity.destinationKey}`;
    }

    getPseudonym(key, type) {
        const { pseudonym } = this.result;
        return !pseudonym ? '' : `${pseudonym.key}`;
    }

    getPseudonymId(key, type) {
        const { pseudonym } = this.result;
        return !pseudonym ? '' : `${pseudonym.id}`;
    }

    getDate(key, type) {
        const { evalDate } = this.result;
        if( !evalDate )
            return '';
        let str = evalDate.substring(8,10) + '.' + evalDate.substring(5,7) + '.' + evalDate.substring(0,4);
        return `${str}`;
    }
}
