export const VALIDATION_TOKEN_OUT_OF_DATE       = 'Die Zeit für Ihre Anfrage ist abgelaufen. Bitte fordern Sie einen neuen Link für das Zurücksetzen des Passworts an.';
export const VALIDATION_TOKEN_INVALID           = 'Ihre Anfrage konnte nicht zugeordnet werden. Bitte fordern Sie einen neuen Link für das Zurücksetzen des Passworts an. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an Ihre LAG.';
export const RESET_PASSWORD_USER_NOT_FOUND      = 'Ihre E-Mail-Adresse konnte nicht gefunden werden. Bitte kontaktieren Sie Ihre LAG.';
export const INVALID_LOGIN                      = 'Die eingegebene E-Mail-Adresse oder Ihr Passwort ist inkorrekt.';
export const NO_VALID_HASH                      = 'Beim Aufruf der URL ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie Ihre LAG.';
export const NO_VALIDATION_HASH                 = 'Ihre Zugangsdaten konten nicht gefunden werden.';
export const NETWORK_ERROR                      = 'Es konnte keine Verbindung zum Server hergestellt werden. Bitte überprüfen Sie Ihre Internetverbindung.';
export const EMPLOYEE_MAIL_ALREADY_EXIST        = 'Die E-Mail-Adresse existiert bereits.';
export const WRONG_RESULT_LAND                  = 'In der CSV-Datei befinden sich Länderkürzel eines anderen Bundeslandes.';
export const CARE_PROVIDER_NOT_FOUND            = (error) => `In der CSV-Datei wurde ein unbekanntes Pseudonym gefunden (${error.data.context.pseudoName}).`;
export const CARE_PROVIDER_IS_NOT_UNIQUE        = 'In der CSV-Datei befinden sich Pseudonyme, welche nicht eindeutig zugeordnet werden können.';
export const PROCESS_STATEMENTS_EXISTING        = 'Es wurden bereits Stellungnahmeverfahren für die zuvor hochgeladene Ergebnis-Datei eingeleitet. Die Daten können nicht mehr überschrieben werden.';
export const RESULTS_ARE_EMPTY                  = 'Es konnten keine Ergebnisse hochgeladen werden. Prüfen Sie bitte die UNIT-Spalte Ihrer CSV-Datei.';
export const NO_UNIQ_IDENTIFIER                 = (error) => `In der Ergebnis-CSV-Datei wurden doppelte Einträge für Indikator, Jahr, Leistungserbringer und Verfahren gefunden (Jahr: ${error.data.context.reportPeriod}, Indikator: ${error.data.context.indicatorId}, Pseudonym: ${error.data.context.pseudoName})`;
export const STATEMENT_PROCESS_ALREADY_SEND     = `Das Stellungnahmeverfahren wurde bereits eingeleitet.`;
export const STATEMENT_PROCESS_ALREADY_ANSWERED = `Die Anfrage zur Stellungnahme wurde bereits versendet.`;
export const IDENT_NUMBER_ALREADY_EXISTS        = `Die Identifikationsnummer existiert bereits.`;
export const PSEUDONYM_KEY_ALREADY_EXIST        = `Das Pseudonym existiert bereits.`;
export const CARE_PROVIDER_MUST_BE_SET          = `Für das Pseudonym wurde kein Leistungserbringer angegeben.`;
export const MODULE_MUST_BE_SET                 = `Für das Pseudonym wurde kein Verfahren angegeben.`;
export const MODULE_NOT_FOUND                   = `Das Verfahren konnte nicht gefunden werden.`;
export const INDICATOR_ID_ALREADY_EXIST         = `Für die ausgewählte Kennzahl ist bereits eine Vorlage ausgewählt.`;
export const STATEMENT_TEMPLATE_NOT_FOUND       = `Die zu speichernde Vorlage konnte nicht gefunden werden.`;
export const TARGET_ACTUAL_NOT_FOUND            = `Ein passender Soll-Ist-Datensatz konnte nicht gefunden werden`;
export const CANT_CHANGE_PASSWORD_NOT_EQUAL     = `Die Passwörter sind nicht identisch`;
export const PASSWORD_SCHEMA                    = 'Das Passwort muss mindestens acht Zeichen, einen Großbuchstaben, eine Zahl und ein Sonderzeichen aus folgenden Zeichen $@!%*?&.,;#_+- enthalten.'
export const NO_VALID_IDENT_NUMBER              = 'Die eingegebene Identifikationsnummer ist nicht korrekt. Bitte prüfen Sie ihre Eingabe.'
export const PROCESS_STATEMENT_ALREADY_ANSWERED = 'Die Stellungnahme kann nicht gelöscht werden, da sie bereits beantwortet wurde.';
export const NO_RESULTS_FOR_MODULE_AND_YEAR     = 'Für das angegebene Verfahren und Jahr gibt es keine Einträge, die depseudonymisiert werden konnten.';
export const NO_RESULTS_FOUND_TO_SEND_MAILS     = 'Für das Verfahren wurden keine Ergebnisse gefunden, die per E-Mail versendet werden können.';

export default {
    VALIDATION_TOKEN_OUT_OF_DATE,
    VALIDATION_TOKEN_INVALID,
    RESET_PASSWORD_USER_NOT_FOUND,
    INVALID_LOGIN,
    NO_VALID_HASH,
    NO_VALIDATION_HASH,
    NETWORK_ERROR,
    EMPLOYEE_MAIL_ALREADY_EXIST,
    WRONG_RESULT_LAND,
    CARE_PROVIDER_NOT_FOUND,
    PROCESS_STATEMENTS_EXISTING,
    NO_UNIQ_IDENTIFIER,
    STATEMENT_PROCESS_ALREADY_SEND,
    STATEMENT_PROCESS_ALREADY_ANSWERED,
    IDENT_NUMBER_ALREADY_EXISTS,
    PSEUDONYM_KEY_ALREADY_EXIST,
    CARE_PROVIDER_MUST_BE_SET,
    MODULE_NOT_FOUND,
    MODULE_MUST_BE_SET,
    INDICATOR_ID_ALREADY_EXIST,
    STATEMENT_TEMPLATE_NOT_FOUND,
    TARGET_ACTUAL_NOT_FOUND,
    CANT_CHANGE_PASSWORD_NOT_EQUAL,
    PASSWORD_SCHEMA,
    NO_VALID_IDENT_NUMBER,
    PROCESS_STATEMENT_ALREADY_ANSWERED,
    NO_RESULTS_FOUND_TO_SEND_MAILS,
}
